import React from "react";
import {withTranslation} from "react-i18next";

import {ReactComponent as RotateDevice} from '../assets/img/rotate-device.svg';
import "./Rotate.scss";

class Rotate extends React.Component {
  render() {
    return (
      <div className="cbo-rotate">
				<div className="rotate-inner">
					<div className="rotate-text">
						<p>
							{this.props.t('rotate_text')}
						</p>
					</div>

					<div className="rotate-picture">
						<RotateDevice />
					</div>
				</div>
			</div>
    );
  }
}

export default withTranslation()(Rotate);



