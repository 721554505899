import React from "react";
import Button from "./Button";
import Pipe from "./Pipe";

import {withTranslation} from "react-i18next";

import {ReactComponent as Logo} from '../assets/img/logo.svg';

import "./Home.scss";

class Home extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      classActive: false
    }
  }

  fullscreen = () => {
    document.documentElement.requestFullscreen();
  }

  componentDidMount() {
    const that = this;
    setTimeout(() => {
      that.setState({
        classActive: true
      });
    }, 50);
  }

  render() {
    return (
      <div className={`cbo-home ${this.state.classActive ? 'home--active':''}`}>

        <div className="home-background">
          <div className="background-topleft"></div>
          <div className="background-right">
            <Pipe el={ { pipe: true, leak: true } } />
          </div>
          <div className="background-bottom">
            <Pipe el={ { pipe: true, leak: true } } />
          </div>
        </div>

        <div className="home-inner">
          <div className="home-logo">
            <Logo />
          </div>

          <div className={`home-loading ${this.props.loading === true ? 'loading--active':''}`}>
            <div className="loading-title">
              {this.props.t('home_text_loading')}
            </div>
            <div className="loading-bar">
              <div style={{ width: this.props.loaded + '%' }}></div>
            </div>
          </div>

          <div className="home-buttons">
            <div className={`home-button ${this.props.loading === false ? 'button--active':''}`}>
              <Button
                label={this.props.t('home_button_start')}
                handler={this.props.startHandler}
                disabled={this.props.loading === true}
              />
            </div>

            <div className="home-fullscreen">
              <button type="button" onClick={this.fullscreen}>
                {this.props.t('home_button_fullscreen')}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Home);
