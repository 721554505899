import React from "react";
import {ReactComponent as Leak} from '../assets/img/leak.svg';

import "./Pipe.scss";

class Pipe extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      active: false
    };
  }

  clickHandler = ( e ) => {
    this.setState({
      active: true
    });

    this.props.handler();

    setTimeout(() => {
      this.setState({
        active: false
      });
    }, 50)
  };

  render() {
    if (this.props.el.pipe)
      return (
        <div className={`cbo-pipe ${this.props.el.leak ? 'pipe--leak':''} ${this.state.active ? 'pipe--active':''}`}>
          <button type="button" className="pipe-button" onClick={this.clickHandler}></button>
          <div className="pipe-leak">
            <Leak />
          </div>
        </div>
      );
    else return false;
  }
}

export default Pipe;
