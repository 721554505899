import React from "react";

class Sound extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      context: null
    };

    this.initialized = false;
    this.context = null;
    this.sounds = [
      "click",
      "gameover",
      "gamewin",
      "tapclose",
      "applause",
      "music"
    ];
    this.loaded = {};
    this.playing = {};

    // context state at this time is `undefined` in iOS8 Safari

    if (window.audioContext && window.audioContext.state === "suspended") {
      const resume = function () {
        window.audioContext.resume().then(() => {
          if (window.audioContext.state === "running") {
            document.body.removeEventListener("touchend", resume, false);
          }
        });
      };

      document.body.addEventListener("touchend", resume, false);
    }
  }

  init = () => {
    const AudioContext = window.AudioContext || window.webkitAudioContext;
    if (!AudioContext) {
      throw new Error("AudioContext not supported");
    }

    window.audioContext = new AudioContext();

    this.load();
    this.initialized = true;
  };

  load = () => {
    this.sounds.forEach((sound) => {
      const url = process.env.PUBLIC_URL + "/sounds/" + sound + ".mp3";
      const request = new XMLHttpRequest();
      const that = this;

      request.open("GET", url, true);
      request.responseType = "arraybuffer";
      request.onload = function (response) {
        var audioData = request.response;
        window.audioContext.decodeAudioData(
          audioData,
          function (buffer) {
            that.onload(sound, buffer);
          },

          function (e) {
            console.log("Error with decoding audio data" + e.err);
          }
        );
      };

      request.send();
    });
  };

  onload = (el, buffer) => {
    this.loaded[el] = buffer;

    const percent =
      (Object.keys(this.loaded).length / this.sounds.length) * 100;
    this.props.loadedHandler(percent);
  };

  play = (el) => {
    if (window.audioContext) {
      const source = window.audioContext.createBufferSource();
      const buffer = this.loaded[el];

      if (buffer) {
        source.buffer = buffer;
        source.connect(window.audioContext.destination);

        if (source.start) {
          source.start(0);
        } else if (source.play) {
          source.play(0);
        } else if (source.noteOn) {
          source.noteOn(0);
        }

        this.playing[el] = source;
      }
    }
  };

  speed = (el, speed) => {};

  pause = (el) => {
    const source = this.playing[el];
    if (source) {
      source.stop();
    }
  };

  stop = (el) => {
    const source = this.playing[el];
    if (source) {
      source.stop();
    }
  };

  render() {
    return <div></div>;
  }
}

export default Sound;
