import React from "react";
import Grid from "./Grid";
import Water from "./Water";
import Score from "./Score";
import Level from "./Level";
import Time from "./Time";
import Popin from "./Popin";
import Countdown from "./Countdown";
import Pause from "./Pause";
import GameOver from "./GameOver";
import Help from "./Help";
import Leaderboard from "./Leaderboard";

import {withTranslation} from "react-i18next";

import "./Game.scss";

class Game extends React.Component {
  constructor(props) {
    super(props);

    this.clock = null;

    this.state = {
      time: 0,
      duration: 30,
      playTime: 0,
      prevTime: 0,
      startTime: null,
      lastTime: null,
      leakTime: 0,
      leakDelay: 0,
      waterTime: 0,

      lastLeakCoords: null,

      gridRows: 3,
      gridCols: 4,
      gridCels: {},
      gridFree: [],

      level: 1,
      paused: true,
      autoStart: false,
      water: 0,
      score: 0,

      gameOver: false,
      gameWin: false,
      gameHelp: false,

      leaderboard: false
    };

    this.systems = this.systems.bind(this);
  }

  animate = (_time) => {
    let startTime = this.state.startTime;
    if (!this.state.startTime) {
      startTime = _time;
      this.setState({ startTime: _time });
    }

    if (!this.state.lastTime) {
      this.setState({ lastTime: _time });
    }

    const elapsedTime = _time - startTime;

    this.setState(
      {
        time: elapsedTime,
        playTime: this.state.prevTime + elapsedTime,
        lastTime: _time
      },
      () => {
        this.updateWater();
        this.gameControl();

        if (
          !isNaN(this.state.leakTime) &&
          this.state.playTime - this.state.leakTime > this.state.leakDelay
        ) {
          this.setState(
            {
              leakTime: null
            },
            () => {
              this.randomLeak();
            }
          );
        }
      }
    );

    this.clock = requestAnimationFrame(this.animate);
  };

  initLevel = () => {
    const glevel = this.state.level;
    const grows = Math.min(2 + glevel, 5);
    const gcols = Math.min(3 + glevel, 8);

    let delay = 1500 - (150 * this.state.level);
    if(delay < 500) delay = 500;

    this.setState(
      {
        time: 0,
        playTime: 0,
        prevTime: 0,
        startTime: null,
        lastTime: null,
        leakTime: 0,
        leakDelay: delay,
        waterTime: 0,

        gridRows: grows,
        gridCols: gcols,
        gridCels: {},
        gridFree: [],

        water: 0,
        paused: true,
        autoStart: true,

        gameOver: false,
        gameWin: false,
        gameHelp: false,

        leaderboard: false
      },
      () => {
        this.gridCels();
      }
    );
  };

  nextLevel = () => {
    this.setState(
      {
        level: this.state.level + 1
      },
      () => {
        this.initLevel();
      }
    );
  };

  start = () => {
    this.setState({
      autoStart: false,
      paused: false,
      startTime: null
    });
    this.clock = requestAnimationFrame(this.animate);
    this.props.soundHandler("music", "play");
    this.props.playingHandler(true);
  };

  pause = () => {
    this.setState({
      prevTime: this.state.playTime,
      paused: true
    });
    cancelAnimationFrame(this.clock);
    this.props.soundHandler("music", "pause");
    this.props.playingHandler(false);
  };

  reset = () => {
    this.pause();
    this.setState(
      {
        level: 1,
        score: 0
      },
      () => {
        this.initLevel();
      }
    );
    this.props.soundHandler("music", "stop");
  };

  quit = () => {
    this.props.quitHandler();
  };

  gridCels = () => {
    const arr = {};
    for (let ir = 0; ir < this.state.gridRows; ir++) {
      arr[ir] = {};
      for (let ic = 0; ic < this.state.gridCols; ic++) {
        arr[ir][ic] = {};
      }
    }

    this.setState(
      {
        gridCels: arr,
        gridFree: this.getGridFree(arr)
      },
      () => {
        this.gridPipes();
      }
    );
  };

  gridPipes = (_free) => {
    const numCels = this.state.gridRows * this.state.gridCols;
    const numPipes = Math.min(this.state.level + 4, numCels - 4);
    const grids = [];

    let cels = { ...this.state.gridCels };
    let free = [...this.state.gridFree];

    while (grids.length < numPipes) {
      const grid = this.getGridPipe(cels, free);
      if (grid) {
        grids.push(grid);
        cels = grid.cels;
        free = grid.free;
      }
    }

    const lastGrid = grids.slice(-1);

    if (lastGrid[0]) {
      this.setState({
        gridCels: lastGrid[0].cels,
        gridFree: lastGrid[0].free
      });
    }
  };

  getGridFree = (_cels) => {
    const arr = [];
    for (let ir = 0; ir < this.state.gridRows; ir++) {
      for (let ic = 0; ic < this.state.gridCols; ic++) {
        if (!_cels[ir][ic].pipe) {
          arr.push([ir, ic]);
        }
      }
    }

    return arr;
  };

  getGridPipe = (_cels, _free) => {
    const newCels = { ..._cels };

    if (_free.length > 0) {
      const index = Math.floor(Math.random() * _free.length);
      const coords = _free[index];

      newCels[coords[0]][coords[1]] = {
        ..._cels[coords[0]][coords[1]],
        pipe: true,
        leak: false
      };
    } else {
      return "full";
    }

    const newFree = this.getGridFree(newCels);

    return {
      cels: newCels,
      free: newFree
    };
  };

  getGridPipes = (_cels, _noleaking) => {
    const arr = [];
    for (let row in _cels) {
      for (let col in _cels[row]) {
        if (
          _cels[row][col].pipe === true &&
          (!_noleaking || (_noleaking && _cels[row][col].leak === false))
        ) {
          arr.push([row, col]);
        }
      }
    }

    return arr;
  };

  getGridLeaks = (_cels) => {
    const arr = [];
    for (let row in _cels) {
      for (let col in _cels[row]) {
        if (_cels[row][col].pipe === true && _cels[row][col].leak === true) {
          arr.push([row, col]);
        }
      }
    }

    return arr;
  };

  gameControl = () => {
    if (this.state.playTime >= this.state.duration * 1000) {
      if (this.state.water < 100) this.gameWin();
      else this.gameOver();
    }

    if (this.state.water >= 100) {
      this.gameOver();
    }
  };

  gameOver = () => {
    this.pause();
    this.setState({
      gameOver: true
    });
    this.props.soundHandler("gameover", "play");
  };

  gameWin = () => {
    this.pause();
    this.setState({
      gameWin: true
    });

    this.props.soundHandler("gamewin", "play");
    setTimeout(() => this.props.soundHandler("applause", "play"), 1500);
  };

  randomLeak = () => {
    // let's find a not leaking pipe
    const newCels = this.state.gridCels;
    const newPipes = this.getGridPipes({...newCels}, true);
    let lastLeakIndex = null;

    if (this.state.lastLeakCoords) {

      lastLeakIndex = newPipes.findIndex(element => {
        return JSON.stringify(element) === JSON.stringify(this.state.lastLeakCoords);
      })

      if (lastLeakIndex) newPipes.splice(lastLeakIndex);
    }

    if(newPipes.length > 0){

      const index = Math.floor(Math.random() * newPipes.length);
      const coords = newPipes[index];

      if (coords && coords[0] && coords[1]) {
        newCels[coords[0]][coords[1]] = {
          ...newCels[coords[0]][coords[1]],
          leak: true
        };

        this.setState({
          gridCels: newCels,
          leakTime: this.state.playTime,
          lastLeakCoords: null
        });
      } else {
        console.log("All pipes are leaking dumb ass !");
      }
    }
  };

  updateWater = () => {
    if (this.state.playTime - this.state.waterTime > 100) {
      const nbLeaks = this.getGridLeaks(this.state.gridCels).length;
      const nbPipes = this.getGridPipes(this.state.gridCels).length;
      const base = 1000 / ((this.state.duration * 100) / 3);
      const coeff = nbLeaks / nbPipes;
      const inc = coeff * base;

      this.setState({
        water: this.state.water + inc,
        waterTime: this.state.playTime
      });
    }
  };

  musicSpeed = () => {
    const countdown = this.getCountdown();
    if (countdown < 5) {
      const speed = 2 - countdown / 20;
      this.props.soundHandler("music", "speed", speed);
    }
  };

  systems = (system, args) => {
    switch (system) {
      case "pipe_click":
        if (!this.state.paused) {
          const newCels = { ...this.state.gridCels };
          const leaking = newCels[args.row][args.col].leak;
          const score = this.state.score;
          let newScore = leaking ? score + 1 : score - 3;

          if (newScore < 0) newScore = 0;

          newCels[args.row][args.col].leak = !leaking;

          this.setState({
            gridCels: newCels,
            gridFree: this.getGridFree(newCels),
            score: newScore,
            lastLeakCoords: [args.row.toString(), args.col.toString()]
          });

          this.props.soundHandler("tapclose", "play");
        }
        break;

      default:
        break;
    }
  };

  getCountdown = () => {
    const countdown = Math.max(
      this.state.duration - this.state.playTime / 1000,
      0
    );
    return countdown.toFixed(2);
  };

  displayHelp = ( status ) => {

    if(typeof(status) === 'undefined')
      status = true;

    this.setState({
      'gameHelp': status,
      'gameOver': !status
    });
  };

  displayLeaderboard = () => {
    this.setState({
      'gameOver': false,
      'leaderboard': true
    });
  };

  componentDidMount() {
    this.initLevel();

    const that = this;
    window.addEventListener("blur", function () {
      that.pause();
    });

    window.addEventListener("resize", function () {
      that.pause();
    });
  }

  render() {
    return (
      <div className={`cbo-game ${!this.state.pause ? "game--playing" : ""}`}>
        <Grid
          rows={this.state.gridRows}
          cols={this.state.gridCols}
          cels={this.state.gridCels}
          systems={this.systems}
        />

        <Water water={this.state.water} />

        {this.state.gameOver && (
          <GameOver
            helpHandler={this.displayHelp}
            resetHandler={this.reset}
            leaderboardHandler={this.displayLeaderboard}
          />
        )}

        {this.state.gameWin && (
          <Popin
            title={this.props.t('game_text_wintitle')}
            buttonLabel={this.props.t('game_button_nextlevel')}
            buttonHandler={this.nextLevel}
          />
        )}

        {this.state.gameHelp && (
          <Help
            resetHandler={this.reset}
            backHandler={() => this.displayHelp(false)}
          />
        )}

        <Time time={this.getCountdown()} pauseHandler={this.pause} />

        <Level level={`${this.props.t('game_text_level')} ${this.state.level}`} />
        <Score score={this.state.score} />

        <Countdown
          level={this.state.level}
          callback={this.start}
          hidden={!this.state.autoStart}
          start={this.state.autoStart}
        />

        {this.state.paused &&
          !this.state.autoStart &&
          !this.state.gameWin &&
          !this.state.gameOver &&
          !this.state.gameHelp && (
            <Pause
              startHandler={this.start}
              resetHandler={this.reset}
              quitHandler={this.quit}
            />
          )}

        {this.state.leaderboard && (
          <Leaderboard
            score={this.state.score}
            resetHandler={this.reset}
          />
        )}
      </div>
    );
  }
}

export default withTranslation()(Game);
