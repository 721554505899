import React from "react";
import {ReactComponent as WaterA} from "../assets/img/water-a.svg";
import {ReactComponent as WaterB} from "../assets/img/water-b.svg";
import "./Water.scss";

class Water extends React.Component {
  css = () => {
    return { height: this.props.water + "vh" }
  };

  render() {
    return (
      <div className="cbo-water">
        <div className="water-fill" style={this.css()}>
          <div className="fill fill--a"><WaterA /></div>
          <div className="fill fill--b"><WaterB /></div>
        </div>
      </div>
    );
  }
};

export default Water;
