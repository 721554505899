import React, { createRef } from "react";
import "./Countdown.scss";

import {withTranslation} from "react-i18next";

class Countdown extends React.Component {
  constructor(props) {
    super(props);

    this.timer = createRef();
    this.state = {
      countdown: 3
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.start !== this.props.start &&
      !this.timer.current &&
      !this.props.hidden
    ) {
      const that = this;
      this.timer.current = setInterval(() => {
        const countdown = that.state.countdown;
        that.setState({
          countdown: countdown - 1
        });

        if (countdown - 1 === 0) {
          clearInterval(that.timer.current);
          that.timer.current = null;

          setTimeout(() => {
            that.setState({
              countdown: 3
            });
          }, 500);

          if (that.props.callback) {
            that.props.callback();
          }
        }
      }, 1000);
    }
  }

  render() {
    return (
      <div
        className={`cbo-countdown ${
          this.props.hidden ? "countdown--hidden" : ""
        }`}
      >
        <strong>{this.props.t('countdown_text_level')} : {this.props.level}</strong>
        <span>{this.props.t('countdown_text_title')}</span>
        <span className="countdown-separator"></span>
        <span>{this.props.t('countdown_text_prefix')}</span>
        <strong>{this.state.countdown}</strong>
      </div>
    );
  }
}

export default withTranslation()(Countdown);
