import React from "react";
import Popin from "./Popin";
import Button from "./Button";

import {withTranslation} from "react-i18next";

import "./Pause.scss";

class Pause extends React.Component {
  render() {
    return (
      <Popin title={this.props.t('pause_title')}>
        <div className="cbo-pause">
          <Button
            label={this.props.t('pause_button_resume')}
            handler={ this.props.startHandler }
          />
          <br />
          <Button
            label={this.props.t('pause_button_reset')}
            handler={ this.props.resetHandler }
            color="transparent"
          />
          <br />
          <Button
            label={this.props.t('pause_button_quit')}
            handler={ this.props.quitHandler }
            color="transparent"
          />
        </div>
      </Popin>
    );
  }
}

export default withTranslation()(Pause);
