import React, { createRef } from "react";
import Home from "./components/Home";
import Tutorial from "./components/Tutorial";
import Game from "./components/Game";
import Sound from "./components/Sound";
import Rotate from "./components/Rotate";

import { withTranslation } from "react-i18next";

import { ReactComponent as Birdz } from "./assets/img/birdz.svg";
import { ReactComponent as Sketch } from "./assets/img/sketch.svg";

import "./App.scss";

class App extends React.Component {
  constructor() {
    super();

    this.Sound = createRef();

    this.state = {
      tutorial: false,
      gameStart: false,
      loaded: 0,
      loading: false,
      starting: false,
      playingClass: false
    };
  }

  startHandler = () => {
    if (this.state.loaded < 100 && !this.state.loading) {
      this.setState(
        {
          loading: true,
          starting: true
        },
        () => {
          if (this.Sound.current && !this.Sound.current.initialized)
            this.Sound.current.init();
        }
      );
    } else if (!this.state.loading) {
      this.setState({
        tutorial: true,
        gameStart: false,
        loading: false,
        starting: false
      });
    }
  };

  skipHandler = () => {
    this.setState({
      tutorial: false,
      gameStart: true
    });
  };

  quitHandler = () => {
    this.setState({
      gameStart: false
    });
  };

  loadedHandler = (percent) => {
    this.setState(
      {
        loaded: percent
      },
      () => {
        if (percent >= 100 && percent < 999) {
          const that = this;
          setTimeout(() => {
            that.loadedHandler( 999 )
          }, 1000);
        }

        else if(percent === 999 && this.state.starting) {
          this.setState({
            tutorial: true,
            gameStart: false,
            loading: false,
            starting: false
          });
        }
      }
    );
  };

  soundHandler = (sound, action, arg) => {
    if (this.Sound.current) {
      switch (action) {
        case "play":
          this.Sound.current.play(sound);
          break;

        case "pause":
          this.Sound.current.pause(sound);
          break;

        case "stop":
          this.Sound.current.stop(sound);
          break;

        case "speed":
          this.Sound.current.speed(sound, arg);
          break;

        default:
          break;
      }
    }
  };

  languageHandler = (lang) => {
    this.props.i18n.changeLanguage(lang);
  };

  playingHandler = (status) => {
    this.setState({
      playingClass: status
    });
  };

  homeScreen = () => {
    this.setState({
      tutorial: false,
      gameStart: false
    });

    this.soundHandler("music", "stop");
    this.soundHandler("applause", "stop");
    this.soundHandler("gameover", "stop");
    this.soundHandler("gamewin", "stop");
    this.soundHandler("tapclose", "stop");
  };

  componentDidMount() {
    new Event("cbo-button.click");
    const that = this;
    window.addEventListener("cbo-button.click", function () {
      if (that.Sound.current) that.Sound.current.play("click");
    });

    window.addEventListener("click", function () {
      if (that.Sound.current && !that.Sound.current.initialized)
        that.Sound.current.init();
    });
  }

  render() {
    return (
      <div className={`cbo-app ${this.state.playingClass ? 'app--playing':''}`}>
        <header className="app-header">
          <button
            type="button"
            className="header-logo"
            onClick={this.homeScreen}
          >
            <Birdz />
          </button>

          <div className="header-languages">
            <button
              type="button"
              className={this.props.i18n.language === "fr" ? "active" : ""}
              onClick={() => this.languageHandler("fr")}
            >
              <Sketch />
              <span>FR</span>
            </button>

            <button
              type="button"
              className={this.props.i18n.language === "en" ? "active" : ""}
              onClick={() => this.languageHandler("en")}
            >
              <Sketch />
              <span>EN</span>
            </button>
          </div>
        </header>

        {this.state.tutorial && !this.state.gameStart && (
          <Tutorial skipHandler={this.skipHandler} />
        )}

        {!this.state.tutorial && this.state.gameStart && (
          <Game
            soundHandler={this.soundHandler}
            quitHandler={this.quitHandler}
            playingHandler={this.playingHandler}
          />
        )}

        {!this.state.tutorial && !this.state.gameStart && (
          <Home
            startHandler={this.startHandler}
            loaded={this.state.loaded}
            loading={this.state.loading}
          />
        )}

        <Sound ref={this.Sound} loadedHandler={this.loadedHandler} />

        <Rotate />
      </div>
    );
  }
}

export default withTranslation()(App);
