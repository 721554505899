import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Importing translation files

import languageFR from "./languages/fr.json";
import languageEN from "./languages/en.json";

// Creating object with the variables of imported translation files

const resources = {
  fr: {
    translation: languageFR
  },
  en: {
    translation: languageEN
  }
};

//i18N Initialization

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "fr", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;