import React from "react";
import {ReactComponent as Sketch} from '../assets/img/sketch.svg';
import "./Score.scss";

class Score extends React.Component {
  render() {
    return (
      <div className="cbo-score">
        <div className="score-background">
          <Sketch />
        </div>
        <div className="score-counter">
          {this.props.score}
        </div>
      </div>
    );
  }
}

export default Score;
