import React from "react";
import {ReactComponent as Sketch} from '../assets/img/sketch.svg';
import Button from "./Button";

import {withTranslation} from "react-i18next";

import "./GameOver.scss";

class GameOver extends React.Component {
  render() {
    return (
      <div className="cbo-gameover">
        <h1 className="gameover-title">
          <Sketch />
          <span>{this.props.t('gameover_title')}</span>
        </h1>
        <div className="gameover-help">
          <Button
            label={this.props.t('gameover_button_help')}
            handler={ this.props.helpHandler }
            color="white"
          />
        </div>
        <div className="gameover-buttons">
          <Button
            label={this.props.t('gameover_button_reset')}
            handler={ this.props.resetHandler }
            color="transparent"
          />
          <Button
            label={this.props.t('gameover_button_leaderboard')}
            handler={ this.props.leaderboardHandler }
            color="transparent"
          />
        </div>
      </div>
    );
  }
}

export default withTranslation()(GameOver);
