import React from "react";
import "./Time.scss";

class Time extends React.Component {
  render() {
    return (
      <div className="cbo-time">
        {this.props.time}
        <button type="button" className="time-pause" onClick={this.props.pauseHandler}>
          Pause
        </button>
      </div>
    );
  }
}

export default Time;
