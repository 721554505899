import React from "react";
import {ReactComponent as Sketch} from '../assets/img/sketch.svg';
import "./Button.scss";

class Button extends React.Component {
  constructor(props){
    super(props);
    this.clickHandler = this.clickHandler.bind(this);
  }

  clickHandler() {
    const event = new Event('cbo-button.click');
    window.dispatchEvent(event);

    if(this.props.handler)
      this.props.handler();
  }

  render() {

    if(this.props.tag === 'a')
      return (
        <a
          href={this.props.href ? this.props.href : '#'}
          target={this.props.target ? this.props.target : '_self'}
          rel="noreferrer"
          className={`cbo-button ${this.props.color ? ' button--'+this.props.color:'' }`}
        >
        	<span className="button-background">
        		<Sketch />
        	</span>
        	<span className="button-label" dangerouslySetInnerHTML={{__html:this.props.label}}>
        	</span>
        </a>
      );

    else
      return (
        <button
          type={this.props.type ? this.props.type : 'button'}
          className={`cbo-button ${this.props.color ? ' button--'+this.props.color:'' }`}
          onClick={this.clickHandler}
          disabled={this.props.disabled ? this.props.disabled : false}
        >
          <span className="button-background">
            <Sketch />
          </span>
          <span className="button-label" dangerouslySetInnerHTML={{__html:this.props.label}}>
          </span>
        </button>
      );
  }
}

export default Button;
