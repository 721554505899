import React from "react";
import Popin from "./Popin";
import Button from "./Button";

import {withTranslation, Trans} from "react-i18next";
import {ReactComponent as Tap} from '../assets/img/tap.svg';

import './Help.scss';

class Help extends React.Component {
  render() {
    return (
      <Popin>
        <div className="cbo-help">
          <div className="help-picture">
            <h3>{this.props.t('help_title')}</h3>
            <Tap />
          </div>
          <div className="help-content">
            <Trans i18nKey="help_text" />
          </div>
          <div className="help-buttons">
            <Button
              tag="a"
              label={this.props.t('help_button_discover')}
              href={this.props.t('help_button_discover_href')}
              target="_blank"
            /><br />
            <Button
              label={this.props.t('help_button_reset')}
              handler={ this.props.resetHandler }
              color="transparent"
            />
            <Button
              label={this.props.t('help_button_back')}
              handler={ this.props.backHandler }
              color="transparent"
            />
          </div>
        </div>
      </Popin>
    );
  }
}

export default withTranslation()(Help);
