import React from "react";
import Grid from "./Grid";
import Water from "./Water";
import Score from "./Score";
import Level from "./Level";
import Time from "./Time";
import Button from "./Button";

import {withTranslation, Trans} from "react-i18next";

import "./Game.scss";
import "./Tutorial.scss";

class Tutorial extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 1,
      time: 0,
      gridCels: {
        0: {
          0: {},
          1: {},
          2: {
            pipe: true,
            leak: false
          },
          3: {}
        },
        1: {
          0: {},
          1: {},
          2: {
            pipe: true,
            leak: true
          },
          3: {}
        },
        2: {
          0: {},
          1: {},
          2: {},
          3: {}
        }
      },
      water: 10
    };

    this.systems = this.systems.bind(this);
  }

  next = () => {

    const water = (this.state.step >= 2) ? 30 : 10;

    this.setState({
      step: this.state.step + 1,
      water: water
    });
  };

  skip = () => {
    this.props.skipHandler();
  };

  systems = (system, args) => {};

  render() {
    return (
      <div className={`cbo-game game--tutorial game--step${this.state.step}`}>
        <Grid
          rows="3"
          cols="4"
          cels={this.state.gridCels}
          systems={this.systems}
        />

        <Water water={this.state.water} />
        <Time time={this.state.time} />
        <Level level={this.props.t('game_text_tutorial')} />
        <Score score="0" />

        <div className="cbo-tutorial">
          <div className="tutorial-box">
            <div className="box-content">
              {this.state.step === 1 && (
                <Trans i18nKey="tutorial_text_step1" />
              )}

              {this.state.step === 2 && (
                <Trans i18nKey="tutorial_text_step2" />
              )}

              {this.state.step === 3 && (
                <Trans i18nKey="tutorial_text_step3" />
              )}

              {this.state.step === 4 && (
                <Trans i18nKey="tutorial_text_step4" />
              )}
            </div>
            <div className="box-buttons">
              {this.state.step < 4 ? (
                <div>
                  <Button
                    label={this.props.t('tutorial_button_next')}
                    handler={this.next}
                  />
                  <Button
                    label={this.props.t('tutorial_button_skip')}
                    handler={this.skip}
                    color="transparent"
                  />
                </div>
              ) : (
                <div>
                  <Button
                    label={this.props.t('tutorial_button_start')}
                    handler={this.skip}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Tutorial);
