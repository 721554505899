import React from "react";
import Button from "./Button";

import "./Popin.scss";

class Popin extends React.Component {
  render() {
    return (
      <div className="cbo-popin">
        <div className="popin-inner">

          {this.props.title && (
            <h3 className="popin-title">
              { this.props.title }
            </h3>
          )}

          {this.props.children && (
            <div className="popin-content">
              {this.props.children}
            </div>
          )}

          {(this.props.buttonLabel && this.props.buttonHandler) && (
            <div className="popin-button">
              <Button
                label={ this.props.buttonLabel }
                handler={ this.props.buttonHandler }
              />
            </div>
          )}

        </div>
      </div>
    );
  }
}

export default Popin;
