import React from "react";
import Pipe from "./Pipe";

import "./Grid.scss";

class Grid extends React.Component {
  constructor(props) {
    super(props);

    this.systems = this.systems.bind(this);
  }

  systems = (system, args) => {
    this.props.systems(system, args);
  };

  cels = () => {
    let content = [];
    if (this.props.cels && this.props.cels[0]) {
      const height = 100 / this.props.rows;
      const width = 100 / this.props.cols;

      for (let ir = 0; ir < this.props.rows; ir++) {
        for (let ic = 0; ic < this.props.cols; ic++) {
          const style = {
            width: width + "%",
            height: height + "%"
          };

          const current = this.props.cels[ir] ? this.props.cels[ir][ic] : null;

          if (current) {
            const cel = (
              <div
                className={`grid-cel cel--r${ir} cel--c${ic}`}
                style={style}
                key={"cel_" + ir + "_" + ic}
              >
                <div className="cel-pipe pipe--h">
                  <div className="pipe-inner"></div>
                </div>
                <div className="cel-pipe pipe--v">
                  <div className="pipe-inner"></div>
                </div>
                <div className="cel-content">
                  <Pipe
                    cels={this.props.cels}
                    el={current}
                    handler={() =>
                      this.systems("pipe_click", { row: ir, col: ic })
                    }
                  />
                </div>
              </div>
            );

            content.push(cel);
          }
        }
      }
    }

    return content;
  };

  fake = () => {
    let content = [];

    const width = 100 / this.props.cols;
    const height = 100 / this.props.rows;

    for (let ir = 0; ir < this.props.rows; ir++) {
      for (let ic = 0; ic < this.props.cols; ic++) {
        const style = {
          width: width + "%",
          height: height + "%"
        };

        const cel = (
          <div className="grid-cel" style={style} key={"fake_" + ir + "_" + ic}>
            <div className="cel-pipe pipe--h">
              <div className="pipe-inner"></div>
            </div>
            <div className="cel-pipe pipe--v">
              <div className="pipe-inner"></div>
            </div>
          </div>
        );

        content.push(cel);
      }
    }

    return content;
  };

  render() {
    return (
      <div>
        <div className="cbo-grid">{this.cels()}</div>
        <div className="cbo-grid grid--fake">{this.fake()}</div>
      </div>
    );
  }
}

export default Grid;
