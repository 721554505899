import React from "react";
import {ReactComponent as Sketch} from '../assets/img/sketch.svg';
import "./Level.scss";

class Level extends React.Component {
  render() {
    return (
      <div className="cbo-level">
        <div className="level-background">
          <Sketch />
        </div>
        <div className="level-label">
          {this.props.level}
        </div>
      </div>
    );
  }
}

export default Level;
