import React from "react";
import Axios from "axios";
import Button from "./Button";

import {withTranslation, Trans} from "react-i18next";

import "./Leaderboard.scss";

class Leaderboard extends React.Component {
  constructor(props) {
    super(props);

    this.sent = false;
    this.sending = false;

    this.state = {
      step: 1,
      lastname: "",
      firstname: "",
      email: "",
      rgpd: false,
      errors: [],
      nonce: null,
      scores: null,
      key: process.env.REACT_APP_WP_KEY || "Jdxs mblh Wk1o c7vG 55DD 5Z9W",
      domain: process.env.REACT_APP_WP_URL || "http://local.birdz",
      position: 0,
      sending: false
    };
  }

  handleFormSubmit = (e) => {
    const errors = [];
    if(this.state.lastname === ''){
      errors.push('lastname');
    }
    if(this.state.firstname === ''){
      errors.push('firstname');
    }
    if(this.state.email === ''){
      errors.push('email');
    }
    if(!this.state.rgpd){
      errors.push('rgpd');
    }

    this.setState({
      sending: errors.length === 0,
      errors: errors
    },() => {
      e.preventDefault();

      if(errors.length === 0)
        this.sendScore();
    });
  }

  handleInputChange = (e, key) => {
    this.setState(
      {
        [key]: e.target.value
      }
    );
  };

  getScores = () => {
    const that = this;
    Axios.get(that.state.domain + `/wp-json/wp/v2/score?per_page=100&timestamp=${new Date().getTime()}`)
      .then((response) => {
        that.setScores(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  setScores = (scores) => {
    let position = 0;

    scores.sort((a, b) => b.acf.score_value - a.acf.score_value);
    scores.forEach((el, index) => {
      if (el.acf.score_value <= this.props.score && position === 0) {
        position = index + 1;
        el['current'] = true;
      }
    });

    this.setState({
      sending: false,
      scores: scores,
      position: position,
      step: 2
    });
  };

  sendScore = () => {
    if(this.sent === false){
      this.sent = true;

      const that = this;
      Axios.post(
        that.state.domain + "/wp-json/wp/v2/score",
        {
          title: that.state.firstname + " " + that.state.lastname,
          status: "publish",
          acf: {
            score_firstname: that.state.firstname,
            score_lastname: that.state.lastname,
            score_email: that.state.email,
            score_value: that.props.score
          }
        },
        {
          auth: {
            username: "api",
            password: that.state.key
          }
        }
      )
      .then(() => {
        that.getScores();
      })
      .catch((error) => {
        console.log(error);
        that.sent = false;
        that.setState({
          sending: false
        })
      });
    }
  };

  prependZeros = (num, size) => {
    let s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  };

  render() {
    return (
      <div className="cbo-leaderboard">
        <div className="leaderboard-inner">
          {this.state.step === 1 && (
            <div className="leaderboard-step">
              <h2>{this.props.t('leaderboard_step1_title')}</h2>
              <p>
                <Trans i18nKey="leaderboard_step1_text" />
              </p>
              <form className="step-form" onSubmit={this.handleFormSubmit}>
                <div className="form-field">
                  <input
                    type="text"
                    value={this.state.lastname}
                    onChange={(e) => this.handleInputChange(e, "lastname")}
                    placeholder={this.props.t('leaderboard_step1_label_lastname')}
                    className={this.state.errors.indexOf('lastname') !== -1 ? 'invalid':''}
                    required
                  />
                </div>
                <div className="form-field">
                  <input
                    type="text"
                    value={this.state.firstname}
                    onChange={(e) => this.handleInputChange(e, "firstname")}
                    placeholder={this.props.t('leaderboard_step1_label_firstname')}
                    className={this.state.errors.indexOf('firstname') !== -1 ? 'invalid':''}
                    required
                  />
                </div>
                <div className="form-field field--full">
                  <input
                    type="email"
                    value={this.state.email}
                    onChange={(e) => this.handleInputChange(e, "email")}
                    placeholder={this.props.t('leaderboard_step1_label_email')}
                    className={this.state.errors.indexOf('email') !== -1 ? 'invalid':''}
                    required
                  />
                </div>
                <div className="form-field field--rgpd">
                  <input
                    type="checkbox"
                    value={this.state.rgpd}
                    onChange={(e) => this.handleInputChange(e, "rgpd")}
                    id="leaderboardRgpd"
                    className={this.state.errors.indexOf('rgpd') !== -1 ? 'invalid':''}
                    required
                  />
                  <label htmlFor="leaderboardRgpd">
                    {this.props.t('leaderboard_step1_label_rgpd')}
                  </label>
                </div>
                <div className={`form-submit ${this.state.sending ? 'loading':''}`}>
                  <div className="cbo-spinner">
                    <div></div><div></div><div></div><div></div>
                  </div>
                  <Button
                    type="submit"
                    label={this.props.t('leaderboard_step1_button_submit')}
                  />
                </div>
              </form>
            </div>
          )}

          {this.state.step === 2 && (
            <div className="leaderboard-step step--scores">
              <div className="step-summary">
                <h2>{this.props.t('leaderboard_step2_title')}</h2>

                <div className="summary-score">
                  <p>{this.props.t('leaderboard_step2_text_yourscoreis')}</p>
                  <span className="score-value">{this.props.score}</span>
                  {this.state.position > 0 ? (
                    <p>
                      {this.state.position > 1 ? (
                        <span>
                          {this.props.t('leaderboard_step2_text_youare')}&nbsp;
                          <span className="score-position">
                            {this.state.position}{this.props.t('leaderboard_step2_text_nth')}
                          </span>
                          &nbsp;{this.props.t('leaderboard_step2_text_youare_suffix')}
                        </span>
                      ):(
                        <span>
                          {this.props.t('leaderboard_step2_text_newrecord')}
                        </span>
                      )}
                    </p>
                  ) : (
                    <p>
                      {this.props.t('leaderboard_step2_text_youareafter')}&nbsp;
                      <span className="score-position">
                        100{this.props.t('leaderboard_step2_text_nth')}
                      </span>
                      &nbsp;{this.props.t('leaderboard_step2_text_youareafter_suffix')}
                    </p>
                  )}
                </div>

                <div className="summary-bottom">
                  <Button label="Rejouer" handler={this.props.resetHandler} />

                  <div className="bottom-share">
                    {this.props.t('leaderboard_step2_text_share')}
                  </div>
                </div>
              </div>

              {this.state.position <= 3 && (
                <div className="step-scores">
                  {this.state.scores.map((el, index) => (
                    <div key={`score_${index}`}>
                      {index < 10 && (
                        <div className={`scores-el ${el.current ? ' el--current':''}`}>
                          <span className="el-position">{this.prependZeros(index + 1, 3)}</span>
                          <span className="el-score">{el.acf.score_value}</span>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}

              {this.state.position > 3 && (
                <div className="step-scores">
                  {this.state.scores.map((el, index) => {
                    return (
                      <div key={`score_${index}`}>
                        {((index < 3 || index >= this.state.position - 2) && index < this.state.position + 5) && (
                          <div className={`scores-el ${el.current ? ' el--current':''}`}>
                            <span className="el-position">{this.prependZeros(index + 1, 3)}</span>
                            <span className="el-score">{el.acf.score_value}</span>
                          </div>
                        )}

                        {index === 3 && this.state.position - 1 > 4 && (
                          <div className="scores-separator">...</div>
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation()(Leaderboard);
